import React, { useRef } from 'react';
// import ReactDOM from 'react-dom';


const App = () => {
    const servicesRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <header>
                <nav>
                    <img src="logo.png" alt="Modulus-AI Logo" />
                    <ul>
                        <li><a href="#" onClick={() => scrollToSection(servicesRef)}>Services</a></li>
                        <li><a href="#" onClick={() => scrollToSection(aboutRef)}>About</a></li>
                        <li><a href="#" onClick={() => scrollToSection(contactRef)}>Contact</a></li>
                    </ul>
                </nav>
            </header>
            <section className="hero">
                <div className="hero-content">
                    <h1>Modulus-AI</h1>
                    <p>Innovative solutions for Planning, Designing, Automation, and Artificial Intelligence.</p>
                    <a href="#" className="cta-button" onClick={() => scrollToSection(servicesRef)}>Learn More</a>
                </div>
            </section>
            <div ref={servicesRef}>
                <section className="service-section">
                  <div className='service-container'>
                    <div className='service-image left'>
                      <img src='planning.jpg' alt='Planning' />
                    </div>
                    <div className='service-description'>
                      <h2>Research & Development (R&D)</h2>
                      <div className="service-description">
                      
                        <p>At Modulus-AI, our R&D team is constantly pushing the boundaries of what's possible with artificial intelligence. We invest heavily in cutting-edge AI research to develop novel algorithms, architectures and techniques that can drive transformative innovations for your business.</p>
                        <p>Our R&D efforts span a wide range of AI domains including deep learning, reinforcement learning, transfer learning, explainable AI, and more. By staying at the forefront of AI research, we ensure that our clients have access to the latest and most advanced AI capabilities.</p>

                      </div>
                    </div>
                  </div>
                </section>
                <section className="service-section">
                <div className='service-container'>
                    
                    <div className='service-description'>
                      <h2>Designing</h2>
                      <div className="service-description">
                          <p>Our team of experts works closely with you to plan and design tailored AI solutions that align with your unique needs and goals. We invest in understanding your business thoroughly to architect the right scalable AI systems. With Modulus-AI as your partner, you can be confident in building a robust, future-proof AI strategy and infrastructure.</p>
                      </div>
                    </div>
                    <div className='service-image right'>
                      <img src='designing.jpg' alt='Designing' />
                    </div>
                  </div>
                    
                </section>
                <section className="service-section">
                <div className='service-container'>
                    <div className='service-image left'>
                      <img src='automation.jpg' alt='Auto mation' />
                    </div>
                    <div className='service-description'>
                      <h2>Automation</h2>
                      <div className="service-description">
                          <p>Modulus-AI excels at optimizing and automating business processes using cutting-edge artificial intelligence techniques. Our AI-driven automation solutions can lead to significant cost reductions and productivity gains for your organization. We help you implement intelligent automation to streamline workflows and achieve your business objectives faster.</p>
                      </div>
                    </div>
                  </div>
                  
                    
                </section>
                <section className="service-section">

                <div className='service-container'>
                    
                    <div className='service-description'>
                      <h2>Artificial Intelligence</h2>
                      <div className="service-description">
                          <p>Modulus-AI is at the forefront of applying AI to transform industries and open up new opportunities. From computer vision to natural language processing and machine learning, we cover the full spectrum of AI technologies. We can help you leverage AI for diverse use cases - improving customer service, optimizing supply chains, aiding decision-making, and even creating new products and revenue streams.</p>
                      </div>
                    </div>
                    <div className='service-image right'>
                      <img src='ai.jpg' alt='Artificial Intelegence' />
                    </div>
                  </div>

                    
                </section>
            </div>
            <section className="about-section" ref={aboutRef}>
                <h2>About Us</h2>
                <div className="team-member">
                    <img src="md.jpg" alt="Marijan Dimovski" />
                    <h3>Marijan Dimovski</h3>
                    <p>CEO</p>
                </div>
                <div className="team-member">
                    <img src="db.jpg" alt="Dimitar Blazevski" />
                    <h3>Dimitar Blazevski</h3>
                    <p>FEO</p>
                </div>
                <div className="team-member">
                    <img src="gg.jpg" alt="Georgy Georgievski" />
                    <h3>Georgy Georgievski</h3>
                    <p>CTO</p>
                </div>
            </section>
            <section className="contact-section" ref={contactRef}>
                <h2>Contact Us</h2>
                <form>
                    <input type="text" placeholder="Name" required />
                    <input type="email" placeholder="Email" required />
                    <textarea placeholder="Message" required></textarea>
                    <button type="submit">Send</button>
                </form>
            </section>
            <footer>
                <p>&copy; 2023 Modulus-AI. All rights reserved.</p>
                <p>
                    <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a>
                </p>
            </footer>
        </>
    );
};

export default App;
// ReactDOM.render(<App />, document.getElementById('root'));